<template>
  <div>
    <CRow ref="shopCategorySection">
      <CCol col="12">
        <ShopTable
            :items="shopTableData"
            :fields="tableFields"
            hover
            striped
            border
            small
            fixed
            caption="店舗リスト"
        />
      </CCol>

      <CElementCover :opacity="0.8" v-if="loading"/>
    </CRow>
  </div>
</template>

<script>
import ShopTable from "../../components/shop/ShopTable";
import {mapState} from "vuex";

export default {
  components: {
    ShopTable
  },

  computed: {
    ...mapState({
      shopList: state => state.shop.shopList,
    }),

    shopTableData() {
      return this.shopList.map(shopItem => {
        return {
          uuid: shopItem.uuid,
          name: shopItem.name,
          city: '',
          categories_name: shopItem.categories.length ? shopItem.categories.map(categoryItem => {
            return categoryItem.name;
          }).join(', ') : '',
        };
      });
    }
  },

  data() {
    return {
      loading: false,
      tableFields: [
        {
          key: 'uuid',
          label: 'ID',
        },
        {
          key: 'name',
          label: '店舗名'
        },
        {
          key: 'city',
          label: '都市'
        },
        {
          key: 'categories_name',
          label: 'カテゴリー'
        },
        {
          key: 'action',
          label: '編集'
        }
      ],
    }
  },

  mounted() {
    this.loading = true;

    this.$store.dispatch('shop/getShopList')
        .finally(() => {
          this.loading = false;
        });
  },

  metaInfo: {
    title: 'Shop Management'
  }
};
</script>
