<template>
  <b-modal
      id="shop-detail-modal"
      :title="'Shop Name'"
      centered
      hide-footer
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      @show="onModalShow"
  >
    <CRow>
      <CCol col="12">
        <h5>Basic Information</h5>
        <hr>
      </CCol>
      <CCol col="12">
        <CRow>
          <CCol col="12" md="4">

          </CCol>
          <CCol col="12" md="8">
              <table class="table table-bordered table-striped table-hover">
                <tr>
                  <td class="font-weight-bold bg-secondary">Name</td>
                  <td>Shop Name</td>
                </tr>
                <tr>
                  <td class="font-weight-bold bg-secondary">Owner</td>
                  <td>Shop Onwer</td>
                </tr>
                <tr>
                  <td class="font-weight-bold bg-secondary">Categories</td>
                  <td>Shop Categories</td>
                </tr>
                <tr>
                  <td class="font-weight-bold bg-secondary">Address</td>
                  <td>Shop Address</td>
                </tr>
              </table>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="12">
        <hr>
      </CCol>
      <CCol col="12" class="text-right">
          <CButton :to="{ name: 'ShopEdit', params: { id: 1 } }" color="primary">Edit</CButton>
      </CCol>
    </CRow>
  </b-modal>
</template>

<script>
export default {
  props: {
    currentShopId: {
      type: String,
      required: true,
    }
  },
  methods: {
    onModalShow() {

    }
  }
}
</script>
