<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CRow>
          <CCol class="d-flex align-items-center">
            <CIcon :content="titleIcon" class="mr-2" />
            {{ caption }}
          </CCol>
          <CCol class="d-flex align-items-center justify-content-end">
            <CButton
                color="success"
                square
                size="sm"
                :to="{ name: 'ShopCreate' }"
            >
              店舗を追加
            </CButton>
          </CCol>
        </CRow>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :table-filter="{ label: 'フィルター' }"
          :items-per-page-select="{label: '表示件数' }"
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :items="items"
          :fields="fields"
          :dark="dark"
          sorter
          pagination
      >
        <template #action="{ item }">
          <td>
            <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="mx-1"
                :to="{name : 'ShopEdit', params: { uuid: item.uuid }}"
            >Edit
            </CButton>
            <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                class="mx-1"
                @click="deleteShop(item.uuid)"
            >Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

    <ShopDetail :current-shop-id="shopId" />
  </CCard>
</template>

<script>
import ShopDetail from "./ShopDetail";

export default {
  name: "ShopTable",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["username", "registered", "role", "status"];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  components: {
    ShopDetail
  },
  data() {
    return {
      shopId: '',

      titleIcon: [
        "512 512",
        "<path fill='var(--ci-primary-color, currentColor)' d='M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z' class='ci-primary'/>",
      ],
    };
  },
  methods: {
    deleteShop(id) {
      this.$swal
          .fire({
            title: "Delete Shop ",
            text: "Do you really want to delete selected shop?",
            icon: "info",
            showCancelButton: true,
            showConfirmButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show({
                canCancel: false,
              });

              this.$store
                  .dispatch("shop/deleteShop", id)
                  .then((res) => {
                    let { success } = res.data;

                    if (success) {
                      this.$swal.fire({
                        title: "Delete Shop",
                        text: "Delete Shop Successfully!",
                        icon: "success",
                        willClose: () => {
                          this.$store.dispatch("shop/getShopList");
                        },
                      });
                    } else {
                      this.$swal.fire({
                        title: "Delete Shop",
                        text: "Delete Shop Failed!",
                        icon: "error",
                      });
                    }
                  })
                  .catch(() => {
                    this.$swal.fire({
                      title: "Delete Shop",
                      text: "Delete Shop Failed!",
                      icon: "error",
                    });
                  })
                  .finally(() => {
                    loader.hide();
                  });
            }
          });
    },
  },
};
</script>
